.faq {
    margin-top: 100px;
}

.faq_content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 15px;
}

.faq_title {
    color: black;
    font-size: 50px;
    margin: 10px;
}

.faq_sub_title {
    width: 60vmin;
    color: black;
    font-weight: bold;
    font-size: 25px;
}

.faq_description {
    font-size: 25px;
    margin-top: 10px;
}

.faq_item_title {
    font-size: 25px;
    margin-top: 40px;
}

.faq_hr {
    margin-top: 5px;
}

.faq_item_description {
    font-size: 20px;
    margin-top: 10px;
}

.faq_message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    font-size: 20px;
}


@media only screen and (min-width: 360px) and (max-width: 740px) {
    .faq_content {
        flex-direction: column;
    }

    .faq_title {
        font-size: 25px;
    }

    .faq_sub_title {
        font-size: 20px;
    }

    .faq_description {
        font-size: 15px;
    }

    .faq_item_title {
        font-size: 20px;
    }

    .faq_item_description {
        font-size: 15px;
    }

    .faq_message {
        font-size: 20px;
    }

}

@media only screen and (min-width: 740px) and (max-width: 1024px) {

    .faq_content {
        flex-direction: column;
    }

    .faq_title {
        font-size: 25px;
    }

    .faq_sub_title {
        font-size: 20px;
    }

    .faq_description {
        font-size: 15px;
    }

    .faq_message {
        font-size: 20px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .faq_content {
        flex-direction: column;
    }

    .faq_title {
        font-size: 25px;
    }

    .faq_sub_title {
        font-size: 20px;
    }

    .faq_description {
        font-size: 15px;
    }

    .faq_message {
        font-size: 20px;
    }
}