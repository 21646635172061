.service {
    margin-top: 100px;
}

.service_content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 15px;
}

.service_title {
    color: black;
    font-size: 50px;
    margin: 10px;
}

.service_sub_title {
    width: 60vmin;
    color: black;
    font-weight: bold;
    font-size: 25px;
}

.service_description {
    font-size: 25px;
    margin-top: 10px;
}

.service_item_title {
    font-size: 25px;
    margin-top: 40px;
}

.service_hr {
    margin-top: 5px;
}

.service_item_description {
    font-size: 20px;
    margin-top: 10px;
}


@media only screen and (min-width: 360px) and (max-width: 740px) {
    .service_content {
        flex-direction: column;
    }

    .service_title {
        font-size: 25px;
    }

    .service_sub_title {
        font-size: 20px;
    }

    .service_description {
        font-size: 15px;
    }

    .service_item_title {
        font-size: 20px;
    }

    .service_item_description {
        font-size: 15px;
    }



}

@media only screen and (min-width: 740px) and (max-width: 1024px) {

    .service_content {
        flex-direction: column;
    }

    .service_title {
        font-size: 25px;
    }

    .service_sub_title {
        font-size: 20px;
    }

    .service_description {
        font-size: 15px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .service_content {
        flex-direction: column;
    }

    .service_title {
        font-size: 25px;
    }

    .service_sub_title {
        font-size: 20px;
    }

    .service_description {
        font-size: 15px;
    }
}