* {
    padding: 0%;
    margin: 0%;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: aliceblue;

}

header div {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.header_site_logo {
    width: 40px;
    height: 40px;
    padding: 5%;
}

.header_site_name {
    color: rgb(3, 151, 47);
    font-size: 25px;
    font-weight: bold;
    margin-left: 15px;
    display: flex;
}

.main_menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 15px;
    align-items: center;
}

.dropbtn {
    width: 25px;
    height: 25px;
    color: white;
}

.dropdown {
    display: none;

}

.dropdown-content {
    display: none;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: white;
    float: right;

}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f3f3f3;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}


.links {
    list-style: none;
    text-decoration: none;
    color: black;
    background-color: aliceblue;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    border-radius: 5%x;

}

.menu_item {
    margin-left: 20px;
    list-style: none;
    font-weight: bold;
    align-items: center;
    color: black;

}

.user_profile_logo {
    border-radius: 100%;
    background-color: blue;
    width: 25px;
    height: 25px;
    padding: 2px;


}

@media only screen and (min-width: 360px) and (max-width: 740px) {
   
    .header_site_logo {
        width: 55px;
        height: 55px;
    }

    .header_site_name {
        font-size: 25px;
    }


    .main_menu {
        display: none;
    }


    .dropdown {
        position: relative;
        display: inline-block;
        width: 25px;
        cursor: pointer;
        height: 25px;
    }


}

@media only screen and (min-width: 740px) and (max-width: 1023px) {
    .header_site_logo {
        width: 55px;
        height: 55px;
    }

    .header_site_name {
        font-size: 25px;
    }


    .main_menu {
        display: none;
    }


    .dropdown {
        position: relative;
        display: inline-block;
        width: 25px;
        cursor: pointer;
        height: 25px;
    }


}