.download {
    margin-top: 100px;
}

.download_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.download_image {
    width: 100vmin;
}

.download_title {
    color: black;
    font-size: 50px;
    text-align: start;
    justify-content: start;
    margin: 10px;
}

.download_sub_title {
    font-size: 40px;
}

.download_description {
    font-size: 25px;
    margin-top: 20px;
}

.download_download_image {
    width: 100vmin;
}

.download_hand_book_download_btn {
    width: 20vmin;
    height: 10vmin;
    margin-top: 10px;
    cursor: pointer;
}


@media only screen and (min-width: 360px) and (max-width: 740px) {
    .download_content {
        flex-direction: column;
    }

    .download_title {
        font-size: 25px;
    }

    .download_sub_title {
        font-size: 20px;
    }

    .download_description {
        font-size: 15px;
    }



}

@media only screen and (min-width: 740px) and (max-width: 1024px) {

    .download_content {
        flex-direction: column;
    }

    .download_title {
        font-size: 25px;
    }

    .download_sub_title {
        font-size: 20px;
    }

    .download_description {
        font-size: 15px;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .download_content {
        flex-direction: column;
    }

    .download_title {
        font-size: 25px;
    }

    .download_sub_title {
        font-size: 20px;
    }

    .download_description {
        font-size: 15px;
    }

}