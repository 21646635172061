.home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 15px;

}

.title {
    font-size: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgb(0, 122, 41);
    margin-top: 60px;
}

.type_writer {
    font-size: 40px;
    color: #025f53;
}

.home_logo {
    width: 60vmin;
    height: 60vmin;
    padding: 100px;
}

.website_description {
    font-size: 25px;
    margin-top: 20px;
}

/* Media query for phones (portrait and landscape) */
@media only screen and (min-width: 360px) and (max-width: 740px) {

    .home {
        flex-direction: column;
    }

    .title {
        font-size: 25px;
    }

    .type_writer {
        font-size: 20px;
    }


    .website_description {
        font-size: 15px;

    }

}

@media only screen and (min-width: 740px) and (max-width: 1024px) {

    .home {
        flex-direction: column;
    }

    .title {
        font-size: 25px;
    }

    .type_writer {
        font-size: 20px;
    }


    .website_description {
        font-size: 15px;

    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .home {
        flex-direction: column;
    }

    .title {
        font-size: 25px;
    }

    .type_writer {
        font-size: 20px;
    }


    .website_description {
        font-size: 15px;

    }
}