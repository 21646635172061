footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: aliceblue;
    padding: 10px;
    text-align: center;

}



@media only screen and (min-width: 360px) and (max-width: 740px) {

    .footer_box_one {
        display: none;
    }

    .footer_box_three {
        display: none;
    }
}

@media only screen and (min-width: 740px) and (max-width: 1023px) {
    .footer_box_one {
        display: none;
    }

    .footer_box_three {
        display: none;
    }

}