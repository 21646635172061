.contact {
    margin-top: 100px;
    margin-bottom: 50px;
}

.contact_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    justify-content: start;
    padding: 15px;
}

.contact_title {
    color: black;
    font-size: 50px;
    margin: 10px;
}

.contact_sub_title {
    width: 60vmin;
    color: black;
    font-size: 20px;
}

.contact_description {
    font-size: 25px;
    margin-top: 25px;
    margin-left: 20px;
    margin-bottom: 25px;
}

.contact_feedbak_descrition {
    color: black;
    font-size: 20px;
    margin-top: 10px;
}

.contact_feedbak_email {
    color: black;
    font-size: 20px;
    margin-top: 10px;
}

.contact_feedbak_message {
    color: black;
    font-size: 20px;
    margin-top: 10px;
}

.contact_hr {
    margin-top: 5px;
}


@media only screen and (min-width: 360px) and (max-width: 740px) {
    .contact_content {
        flex-direction: column;
    }

    .contact_title {
        font-size: 25px;
    }

    .contact_sub_title {
        font-size: 20px;
    }

    .contact_description {
        font-size: 15px;
    }

    .contact_feedbak_descrition {
        font-size: 15px;
    }

    .contact_feedbak_email {
        font-size: 15px;
    }

    .contact_feedbak_message {
        font-size: 15px;
    }


}

@media only screen and (min-width: 740px) and (max-width: 1024px) {

    .contact_content {
        flex-direction: column;
    }

    .contact_title {
        font-size: 25px;
    }

    .contact_sub_title {
        font-size: 20px;
    }

    .contact_description {
        font-size: 15px;
    }

    .contact_feedbak_descrition {
        font-size: 15px;
    }

    .contact_feedbak_email {
        font-size: 15px;
    }

    .contact_feedbak_message {
        font-size: 15px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .contact_content {
        flex-direction: column;
    }

    .contact_title {
        font-size: 25px;
    }

    .contact_sub_title {
        font-size: 20px;
    }

    .contact_description {
        font-size: 15px;
    }

    .contact_feedbak_descrition {
        font-size: 15px;
    }

    .contact_feedbak_email {
        font-size: 15px;
    }

    .contact_feedbak_message {
        font-size: 15px;
    }
}