.about {
    margin-top: 100px;
}

.about_content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 15px;
}

.about_title {
    color: black;
    font-size: 50px;
    margin: 10px;
}

.about_sub_title {
    width: 60vmin;
    color: black;
    font-weight: bold;
    font-size: 25px;
}

.about_description {
    font-size: 25px;
    margin-top: 0px;
}

.about_item_title {
    font-size: 25px;
    margin-top: 40px;
}

.about_hr {
    margin-top: 5px;
}

.about_item_description {
    font-size: 20px;
    margin-top: 10px;
}


@media only screen and (min-width: 360px) and (max-width: 740px) {
    .about_content {
        flex-direction: column;
    }

    .about_title {
        font-size: 25px;
    }

    .about_sub_title {
        font-size: 20px;
    }

    .about_description {
        font-size: 15px;
    }

    .about_item_title {
        font-size: 20px;
    }

    .about_item_description {
        font-size: 15px;
    }



}

@media only screen and (min-width: 740px) and (max-width: 1024px) {

    .about_content {
        flex-direction: column;
    }

    .about_title {
        font-size: 25px;
    }

    .about_sub_title {
        font-size: 20px;
    }

    .about_description {
        font-size: 15px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .about_content {
        flex-direction: column;
    }

    .about_title {
        font-size: 25px;
    }

    .about_sub_title {
        font-size: 20px;
    }

    .about_description {
        font-size: 15px;
    }
}